import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Carousel from '../components/carousel'
import Map from '../components/map'
import Footer from '../components/footer'
import './chi-siamo.css'

const ChiSiamo = (props) => {
  return (
    <div className="chi-siamo-container">
      <Helmet>
        <title>Chi-siamo - Shardana Beach Club Liscia Ruja</title>
        <meta
          name="description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
        <meta
          property="og:title"
          content="Chi-siamo - Shardana Beach Club Liscia Ruja"
        />
        <meta
          property="og:description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name3"></Header>
      <div className="chi-siamo-banner">
        <h1 className="chi-siamo-text">Chi siamo?</h1>
        <span className="chi-siamo-text01">
          <span>
            Con passione e rispetto per il nostro territorio, dagli anni 2000
            lavoriamo nel settore dell’ospitalità nella Costa Smeralda.
          </span>
          <br></br>
          <br></br>
          <span>
            Il nostro stabilimento balneare a Liscia Ruja è nato dall&apos;amore
            per il mare e dall&apos;impegno a offrire esperienze di qualità ai
            nostri ospiti.
          </span>
          <br></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
      </div>
      <Carousel rootClassName="carousel-root-class-name"></Carousel>
      <div className="chi-siamo-banner1">
        <h1 className="chi-siamo-text09">Come arrivare?</h1>
      </div>
      <Map rootClassName="map-root-class-name2"></Map>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  )
}

export default ChiSiamo
