import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './carousel.css'

const Carousel = (props) => {
  const [slideNumber, setSlideNumber] = useState(1)
  return (
    <div className={`carousel-container ${props.rootClassName} `}>
      {slideNumber === 1 && (
        <div className="carousel-slide1">
          <img
            alt={props.imageAlt2}
            src={props.imageSrc2}
            className="carousel-image"
          />
          <div onClick={() => setSlideNumber(3)} className="carousel-left-btn">
            <svg viewBox="0 0 1024 1024" className="carousel-icon">
              <path
                d="M658 316l-196 196 196 196-60 60-256-256 256-256z"
                className=""
              ></path>
            </svg>
          </div>
          <div onClick={() => setSlideNumber(2)} className="carousel-right-btn">
            <svg viewBox="0 0 1024 1024" className="carousel-icon02">
              <path
                d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
      {slideNumber === 2 && (
        <div className="carousel-slide2">
          <img
            alt={props.imageAlt22}
            src={props.imageSrc22}
            className="carousel-image1"
          />
          <div onClick={() => setSlideNumber(1)} className="carousel-left-btn1">
            <svg viewBox="0 0 1024 1024" className="carousel-icon04">
              <path
                d="M658 316l-196 196 196 196-60 60-256-256 256-256z"
                className=""
              ></path>
            </svg>
          </div>
          <div
            onClick={() => setSlideNumber(3)}
            className="carousel-right-btn1"
          >
            <svg viewBox="0 0 1024 1024" className="carousel-icon06">
              <path
                d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
      {slideNumber === 3 && (
        <div className="carousel-slide3">
          <img
            alt={props.imageAlt21}
            src={props.imageSrc21}
            className="carousel-image2"
          />
          <div onClick={() => setSlideNumber(2)} className="carousel-left-btn2">
            <svg viewBox="0 0 1024 1024" className="carousel-icon08">
              <path
                d="M658 316l-196 196 196 196-60 60-256-256 256-256z"
                className=""
              ></path>
            </svg>
          </div>
          <div
            onClick={() => setSlideNumber(4)}
            className="carousel-right-btn2"
          >
            <svg viewBox="0 0 1024 1024" className="carousel-icon10">
              <path
                d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
      {slideNumber === 4 && (
        <div className="carousel-slide4">
          <img
            alt={props.imageAlt214}
            src={props.imageSrc214}
            className="carousel-image3"
          />
          <div onClick={() => setSlideNumber(3)} className="carousel-left-btn3">
            <svg viewBox="0 0 1024 1024" className="carousel-icon12">
              <path
                d="M658 316l-196 196 196 196-60 60-256-256 256-256z"
                className=""
              ></path>
            </svg>
          </div>
          <div
            onClick={() => setSlideNumber(5)}
            className="carousel-right-btn3"
          >
            <svg viewBox="0 0 1024 1024" className="carousel-icon14">
              <path
                d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
      {slideNumber === 5 && (
        <div className="carousel-slide5">
          <img
            alt={props.imageAlt213}
            src={props.imageSrc213}
            className="carousel-image4"
          />
          <div onClick={() => setSlideNumber(4)} className="carousel-left-btn4">
            <svg viewBox="0 0 1024 1024" className="carousel-icon16">
              <path
                d="M658 316l-196 196 196 196-60 60-256-256 256-256z"
                className=""
              ></path>
            </svg>
          </div>
          <div
            onClick={() => setSlideNumber(6)}
            className="carousel-right-btn4"
          >
            <svg viewBox="0 0 1024 1024" className="carousel-icon18">
              <path
                d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
      {slideNumber === 6 && (
        <div className="carousel-slide6">
          <img
            alt={props.imageAlt212}
            src={props.imageSrc212}
            className="carousel-image5"
          />
          <div onClick={() => setSlideNumber(5)} className="carousel-left-btn5">
            <svg viewBox="0 0 1024 1024" className="carousel-icon20">
              <path
                d="M658 316l-196 196 196 196-60 60-256-256 256-256z"
                className=""
              ></path>
            </svg>
          </div>
          <div
            onClick={() => setSlideNumber(7)}
            className="carousel-right-btn5"
          >
            <svg viewBox="0 0 1024 1024" className="carousel-icon22">
              <path
                d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
      {slideNumber === 7 && (
        <div className="carousel-slide7">
          <img
            alt={props.imageAlt211}
            src={props.imageSrc211}
            className="carousel-image6"
          />
          <div onClick={() => setSlideNumber(6)} className="carousel-left-btn6">
            <svg viewBox="0 0 1024 1024" className="carousel-icon24">
              <path
                d="M658 316l-196 196 196 196-60 60-256-256 256-256z"
                className=""
              ></path>
            </svg>
          </div>
          <div
            onClick={() => setSlideNumber(8)}
            className="carousel-right-btn6"
          >
            <svg viewBox="0 0 1024 1024" className="carousel-icon26">
              <path
                d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
      {slideNumber === 8 && (
        <div className="carousel-slide8">
          <img
            alt={props.imageAlt2111}
            src={props.imageSrc2111}
            className="carousel-image7"
          />
          <div onClick={() => setSlideNumber(7)} className="carousel-left-btn7">
            <svg viewBox="0 0 1024 1024" className="carousel-icon28">
              <path
                d="M658 316l-196 196 196 196-60 60-256-256 256-256z"
                className=""
              ></path>
            </svg>
          </div>
          <div
            onClick={() => setSlideNumber(1)}
            className="carousel-right-btn7"
          >
            <svg viewBox="0 0 1024 1024" className="carousel-icon30">
              <path
                d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
    </div>
  )
}

Carousel.defaultProps = {
  imageSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageAlt: 'image',
  imageSrc2: '/Chi siamo/1-1500w.jpg',
  imageSrc212: '/Chi siamo/6-1500w.jpg',
  imageSrc21: '/Chi siamo/3-1500w.jpg',
  imageAlt213: 'image',
  rootClassName: '',
  imageSrc214: '/Chi siamo/4-1500w.jpg',
  imageSrc2111: '/Chi siamo/8-1500w.jpg',
  imageAlt1: 'image',
  imageSrc1: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageSrc213: '/Chi siamo/5-1500w.jpg',
  imageAlt211: 'image',
  imageAlt21: 'image',
  imageSrc22: '/Chi siamo/2-1500w.jpg',
  imageAlt2: 'image',
  imageAlt2111: 'image',
  imageAlt214: 'image',
  imageSrc211: '/Chi siamo/7-1500w.jpg',
  imageAlt212: 'image',
  imageAlt22: 'image',
}

Carousel.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageSrc212: PropTypes.string,
  imageSrc21: PropTypes.string,
  imageAlt213: PropTypes.string,
  rootClassName: PropTypes.string,
  imageSrc214: PropTypes.string,
  imageSrc2111: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageSrc213: PropTypes.string,
  imageAlt211: PropTypes.string,
  imageAlt21: PropTypes.string,
  imageSrc22: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageAlt2111: PropTypes.string,
  imageAlt214: PropTypes.string,
  imageSrc211: PropTypes.string,
  imageAlt212: PropTypes.string,
  imageAlt22: PropTypes.string,
}

export default Carousel
