import React from 'react'
import { Link } from 'react-router-dom'

import './btn-outline.css'

const BtnOutline = (props) => {
  return (
    <div className="btn-outline-container">
      <Link to="/contatti" className="btn-outline-register button">
        <span className="btn-outline-text">
          <span>Contattaci</span>
          <br></br>
        </span>
      </Link>
    </div>
  )
}

export default BtnOutline
