import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Btn from '../components/btn'
import FeatureCard3 from '../components/feature-card3'
import GalleryCard21 from '../components/gallery-card21'
import Footer from '../components/footer'
import './servizi.css'

const Servizi = (props) => {
  return (
    <div className="servizi-container">
      <Helmet>
        <title>Servizi - Shardana Beach Club Liscia Ruja</title>
        <meta
          name="description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
        <meta
          property="og:title"
          content="Servizi - Shardana Beach Club Liscia Ruja"
        />
        <meta
          property="og:description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name1"></Header>
      <div className="servizi-features">
        <h1 className="servizi-text">
          <span>Il meglio per la tua vacanza</span>
          <br></br>
        </h1>
        <a
          href="https://widget.spiagge.it/stabilimenti-balneari/prenotazione/it-ss-07026-shardana-beach-club/?ybnl=1"
          target="_blank"
          rel="noreferrer noopener"
          className="servizi-link"
        >
          <Btn
            button="Prenota ora"
            rootClassName="btn-root-class-name6"
            className="servizi-component01"
          ></Btn>
        </a>
        <div className="servizi-container1">
          <FeatureCard3
            title="Spogliatoi, Docce &amp; WC"
            imageSrc="/Icons/1-200h.png"
            description="La presenza di spogliatoi, docce e wc nel nostro stabilimento dimostra il nostro impegno per il benessere e la soddisfazione dei clienti inclusi servizi specifici per chi ha disabilità."
            rootClassName="rootClassName"
          ></FeatureCard3>
          <FeatureCard3
            title="Ristorazione"
            imageSrc="/Icons/2-200h.png"
            description="Nel nostro stabilimento balneare offriamo un servizio di piccola ristorazione con una varietà di panini freschi e bibite"
            rootClassName="rootClassName3"
          ></FeatureCard3>
          <FeatureCard3
            title="Salvataggio"
            imageSrc="/Icons/4-200h.png"
            description="Sicurezza garantita con il nostro servizio di salvataggio in mare e il defibrillatore"
            rootClassName="rootClassName5"
          ></FeatureCard3>
          <FeatureCard3
            title="Noleggio"
            imageSrc="/Icons/5-200h.png"
            description="Disponiamo di gommoni, moto d’acqua, pedalò, sup e canoe"
            rootClassName="rootClassName1"
          ></FeatureCard3>
        </div>
        <div className="servizi-banner">
          <h1 className="servizi-text03">
            Hai bisogno di un&apos;auto per le tue vacanze?
          </h1>
          <span className="servizi-text04">
            <span>
              La vacanza in Sardegna con le comodità di Shardana Autonoleggio.
            </span>
            <br></br>
            <span>
              Con il nostro servizio innovativo puoi noleggiare un&apos;auto
              nuova ed efficiente ovunque tu voglia sull&apos;isola,
              garantendoti un&apos;esperienza senza stress e piena di vantaggi.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <a
            href="https://autonoleggioshardana.it"
            target="_blank"
            rel="noreferrer noopener"
            className="servizi-link1"
          >
            <Btn
              button="Noleggia ora"
              rootClassName="btn-root-class-name3"
              className="servizi-component06"
            ></Btn>
          </a>
        </div>
      </div>
      <img
        alt="image"
        src="/testimonial3-min-1500w.jpg"
        className="servizi-image"
      />
      <div className="servizi-gallery">
        <div className="servizi-container2">
          <div className="servizi-container3">
            <div className="servizi-container4">
              <GalleryCard21 rootClassName="rootClassName2"></GalleryCard21>
            </div>
            <div className="servizi-container5">
              <GalleryCard21
                imageSrc="https://images.unsplash.com/photo-1476718406336-bb5a9690ee2a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fGZvb2R8ZW58MHx8fHwxNjI2NDQ5NjUy&amp;ixlib=rb-1.2.1&amp;h=1000"
                rootClassName="rootClassName3"
              ></GalleryCard21>
            </div>
          </div>
          <div className="servizi-container6">
            <GalleryCard21
              imageSrc="https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxmb29kfGVufDB8fHx8MTYyNjQ0OTY1Mg&amp;ixlib=rb-1.2.1&amp;h=1000"
              rootClassName="rootClassName"
            ></GalleryCard21>
          </div>
        </div>
        <div className="servizi-container7">
          <GalleryCard21
            imageSrc="https://images.unsplash.com/photo-1567620905732-2d1ec7ab7445?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE5fHxmb29kfGVufDB8fHx8MTYyNjQ0OTY1Mg&amp;ixlib=rb-1.2.1&amp;h=1000"
            rootClassName="rootClassName4"
          ></GalleryCard21>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Servizi
