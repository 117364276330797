import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Map from '../components/map'
import Footer from '../components/footer'
import './cookie-policy.css'

const CookiePolicy = (props) => {
  return (
    <div className="cookie-policy-container">
      <Helmet>
        <title>Cookie-Policy - Shardana Beach Club Liscia Ruja</title>
        <meta
          name="description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
        <meta
          property="og:title"
          content="Cookie-Policy - Shardana Beach Club Liscia Ruja"
        />
        <meta
          property="og:description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name5"></Header>
      <div className="cookie-policy-banner">
        <h1 className="cookie-policy-text">Cookie Policy</h1>
        <span className="cookie-policy-text01">
          <span>Last Updated: 01-04-2024</span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>
            This Cookie Policy explains what cookies are, how we use them, and
            your choices regarding their use on our website.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>1. What are cookies?</span>
          <br></br>
          <br></br>
          <span>
            Cookies are small text files that are stored on your computer or
            mobile device when you visit a website. They help the website
            recognize your device and remember certain information about your
            visit.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>2. How we use cookies</span>
          <br></br>
          <br></br>
          <span>We use cookies for various purposes, including:</span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>
            Essential cookies: These cookies are necessary for the proper
            functioning of our website. They enable you to navigate the site and
            use its features.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>
            Analytical cookies: We use these cookies to analyze how visitors
            interact with our website. This information helps us improve the
            user experience.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>
            Marketing cookies: These cookies are used to track visitors across
            websites. The intention is to display ads that are relevant and
            engaging for the individual user.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>3. Types of cookies we use</span>
          <br></br>
          <br></br>
          <span>
            Session cookies: These are temporary cookies that are erased when
            you close your browser.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>
            Persistent cookies: These cookies remain on your device for a set
            period or until you delete them manually.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>4. Your choices</span>
          <br></br>
          <br></br>
          <span>
            You can choose to accept or decline cookies. Most web browsers
            automatically accept cookies, but you can usually modify your
            browser settings to decline cookies if you prefer. However, this may
            prevent you from taking full advantage of the website.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>5. Third-party cookies</span>
          <br></br>
          <br></br>
          <span>
            We may use third-party services that use cookies. These third-party
            services have their own privacy policies governing the use of
            cookies.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>6. Updates to this policy</span>
          <br></br>
          <br></br>
          <span>
            We may update this Cookie Policy to reflect changes in our practices
            or for other operational, legal, or regulatory reasons. Please check
            this page periodically for any changes.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>7. Contact us</span>
          <br></br>
          <br></br>
          <span>
            If you have any questions about our Cookie Policy, please contact us
            at stabilimentoshardana.website@gmail.com.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>
            By using our website, you consent to the use of cookies as described
            in this Cookie Policy.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>Shardana Beach Club</span>
          <br></br>
          <span>stabilimentoshardana.website@gmail.com</span>
          <br></br>
          <br></br>
        </span>
      </div>
      <div className="cookie-policy-banner1">
        <h1 className="cookie-policy-text98">Come arrivare?</h1>
      </div>
      <Map rootClassName="map-root-class-name3"></Map>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  )
}

export default CookiePolicy
