import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import GalleryCard22 from '../components/gallery-card22'
import Btn from '../components/btn'
import Map from '../components/map'
import Footer from '../components/footer'
import './la-spiaggia.css'

const LaSpiaggia = (props) => {
  return (
    <div className="la-spiaggia-container">
      <Helmet>
        <title>La-Spiaggia - Shardana Beach Club Liscia Ruja</title>
        <meta
          name="description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
        <meta
          property="og:title"
          content="La-Spiaggia - Shardana Beach Club Liscia Ruja"
        />
        <meta
          property="og:description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <div id="top" className="la-spiaggia-banner">
        <h1 className="la-spiaggia-text">Il nostro paradiso</h1>
        <span className="la-spiaggia-text01">
          <span>
            La spiaggia di Liscia Ruja è una delle mete più ambite della Costa
            Smeralda in Sardegna, famosa per la sua bellezza naturale e le acque
            limpide e turchesi.
          </span>
          <br></br>
          <br></br>
          <span>
            Situata tra Porto Cervo e Porto Rotondo, nel comune di Arzachena,
            questa spiaggia offre un panorama mozzafiato con sabbia bianca,
            fine, con sfumature dorate e rossastre, circondata da una
            lussureggiante macchia mediterranea.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <span>
            A sinistra c’è Cala di Volpe, di fronte le isole di Soffi e
            Mortorio, a destra si intravede la sagoma di Capo Figari.
          </span>
          <br></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
      </div>
      <div className="la-spiaggia-gallery">
        <div className="la-spiaggia-container1">
          <div className="la-spiaggia-container2">
            <div className="la-spiaggia-container3">
              <GalleryCard22
                imageSrc="/shardana_web-8%20(1)-min-1500w.jpg"
                rootClassName="rootClassName2"
              ></GalleryCard22>
            </div>
            <div className="la-spiaggia-container4">
              <GalleryCard22
                imageSrc="/shardana_compressa%20(1)-min-1500h.jpg"
                rootClassName="rootClassName3"
              ></GalleryCard22>
            </div>
          </div>
          <div className="la-spiaggia-container5">
            <GalleryCard22
              imageSrc="/shardana_web-5-min%20(1)-min-1500h.jpg"
              rootClassName="rootClassName"
            ></GalleryCard22>
          </div>
        </div>
        <div className="la-spiaggia-container6">
          <GalleryCard22
            imageSrc="/lisciarujarounded-1500w.jpg"
            rootClassName="rootClassName4"
          ></GalleryCard22>
        </div>
      </div>
      <div className="la-spiaggia-banner1">
        <h1 className="la-spiaggia-text13">Come arrivare</h1>
        <span className="la-spiaggia-text14">
          <span>
            La spiaggia è raggiungibile grazie a una piccola strada in terra
            battuta, ma nonostante l’aspetto selvaggio, è una delle più
            frequentate dell’intera costa e conosciute da jet set e vip
            internazionali.
          </span>
          <br></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>
            Da Porto Cervo si procede in direzione sud lungo la strada
            provinciale 94. Si supera il bivio per Cala di Volpe e si svolta
            subito a destra, dopo circa 100 metri. Da qui procedere lungo la
            stradina bianca fino alla spiaggia.
          </span>
          <br></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>
            Da Olbia, proseguire in direzione nord sulla statale 125. Poi si
            imbocca la provinciale 73 per la Costa Smeralda e, dopo alcuni
            chilometri, si svolta a destra all’altezza di Portisco. Si prosegue
            ancora sulla provinciale 94 fino all’incrocio per Cala di Volpe. Qui
            svoltare a destra e nuovamente a destra, seguendo le indicazioni per
            Liscia Ruja.
          </span>
          <br></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
      </div>
      <a
        href="https://widget.spiagge.it/stabilimenti-balneari/prenotazione/it-ss-07026-shardana-beach-club/?ybnl=1"
        target="_blank"
        rel="noreferrer noopener"
        className="la-spiaggia-link"
      >
        <Btn
          button="Prenota ora il tuo ombrellone"
          rootClassName="btn-root-class-name8"
          className="la-spiaggia-component5"
        ></Btn>
      </a>
      <div className="la-spiaggia-banner2">
        <h1 className="la-spiaggia-text27">
          <span>Dove siamo</span>
          <br></br>
        </h1>
      </div>
      <Map rootClassName="map-root-class-name"></Map>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default LaSpiaggia
