import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Btn from '../components/btn'
import BlogPostCard4 from '../components/blog-post-card4'
import TestimonialCard2 from '../components/testimonial-card2'
import TestimonialCard4 from '../components/testimonial-card4'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div id="top" className="home-container">
      <Helmet>
        <title>Shardana Beach Club Liscia Ruja</title>
        <meta
          name="description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
        <meta property="og:title" content="Shardana Beach Club Liscia Ruja" />
        <meta
          property="og:description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name4"></Header>
      <div className="home-hero">
        <h1 className="home-text">
          <span>L’Oasi di pace nella Costa Smeralda</span>
          <br></br>
        </h1>
        <span className="home-text03">SHARDANA BEACH CLUB</span>
        <a
          href="https://widget.spiagge.it/stabilimenti-balneari/prenotazione/it-ss-07026-shardana-beach-club/?ybnl=1"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link"
        >
          <Btn
            button="Prenota ora il tuo ombrellone"
            rootClassName="btn-root-class-name5"
            className="home-component01"
          ></Btn>
        </a>
      </div>
      <div className="home-banner">
        <h1 className="home-text04">Shardana Beach Club</h1>
        <span className="home-text05">
          <span>
            Con passione e rispetto per il nostro territorio, dagli anni 2000
            lavoriamo nel settore dell’ospitalità nella Costa Smeralda.
          </span>
          <br></br>
          <br></br>
          <span>
            Lo stabilimento Shardana offre il massimo della qualità e del
            comfort per le tue giornate estive nella bellissima spiaggia di
            Liscia Ruja.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            Scegli tra le tre tipologie di lettini e ombrelloni e rilassati in
            riva al mare.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <Link to="/contatti" className="home-navlink">
          <Btn
            button="Prenota ora"
            rootClassName="btn-root-class-name"
            className="home-component02"
          ></Btn>
        </Link>
      </div>
      <div className="home-blog">
        <BlogPostCard4
          date=" "
          label="Liscia Ruja"
          imageAlt="riva-liscia-ruja-shardana-lettini-ombrelloni"
          imageSrc="/testimonial1-min-1500w.jpg"
          description="In Riva al mare della spiaggia più ambita della Costa Smeralda"
          rootClassName="rootClassName1"
        ></BlogPostCard4>
        <div className="home-container01">
          <BlogPostCard4
            date=" "
            label="Esperienza nel settore"
            imageAlt="liscia-ruja-shardana-lettini-ombrelloni"
            imageSrc="/testimonial2-min-1500w.jpg"
            description="Il nostro stabilimento offre alti livelli di comfort, per garantirti indimenticabili giornate al mare"
            rootClassName="rootClassName2"
          ></BlogPostCard4>
          <BlogPostCard4
            date=" "
            label="Relax &amp; Comfort"
            imageAlt="liscia-ruja-shardana-lettini-ombrelloni-aperitivo-in-riva-drink"
            imageSrc="/testimonial3-min-1500w.jpg"
            description="Da anni curiamo ogni dettaglio, per farti vivere un’esperienza unica"
            rootClassName="rootClassName3"
          ></BlogPostCard4>
        </div>
      </div>
      <div className="home-hero1">
        <div className="home-container02">
          <h1 className="home-text14">
            <span>
              Il paradiso di
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>Liscia Ruja</span>
          </h1>
          <span className="home-text18">
            <span className="home-text19">
              Benvenuti nel paradiso di Liscia Ruja, dove il mare cristallino
              bacia una spiaggia dorata incorniciata da dune di sabbia. Le onde
              dolci e le palme maestose creano un&apos;atmosfera di pace e
              tranquillità
            </span>
            <br></br>
          </span>
          <img
            alt="image"
            src="/fotohero3-min-1200w.png"
            className="home-image"
          />
          <Link to="/la-spiaggia" className="home-navlink1">
            <Btn
              button="Scopri"
              rootClassName="btn-root-class-name1"
              className="home-component06"
            ></Btn>
          </Link>
        </div>
        <img
          alt="image"
          src="/fotohero3-min-1200w.png"
          className="home-image1"
        />
      </div>
      <div className="home-banner1">
        <h1 className="home-text21">I nostri clienti</h1>
      </div>
      <div className="home-testimonial">
        <div className="home-container03">
          <div className="home-container04">
            <div className="home-container05">
              <TestimonialCard2
                name="Carlo"
                quote="Personale gentilissimo e premuroso, spiaggia tra le più belle della zona. Ci tornerò senz'altro."
                profileAlt="Carlo"
                profileSrc="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                rootClassName="rootClassName1"
              ></TestimonialCard2>
            </div>
            <TestimonialCard2
              name="Paola"
              quote="La mia spiaggia preferita per il mare bellissimo, per la gentilezza e disponibilità di tutto lo staff! ⭐️⭐️⭐️⭐️⭐️"
              profileAlt="Paola"
              profileSrc="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
              rootClassName="rootClassName2"
            ></TestimonialCard2>
          </div>
          <div className="home-container06">
            <TestimonialCard4
              name="Tatiana"
              quote="Siamo clienti abituali da 8 anni, da giugno a settembre. Quindi siamo super soddisfatti. La spiaggia è bellissima, servizio top, prezzo varia secondo la fila e tipologia lettini. Super consigliato."
              pictureAlt="Tatiana"
              profileSrc="https://images.unsplash.com/photo-1553184118-d20774c4c1db?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxwb3J0cmFpdCUyMGJ3fGVufDB8fHx8MTYyNjQ1MjI0MA&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="rootClassName"
            ></TestimonialCard4>
          </div>
        </div>
      </div>
      <div className="home-hero2">
        <img
          alt="liscai-ruja-illustrazione-mare"
          src="/fotohero2-min-1200w.png"
          className="home-image2"
        />
        <div className="home-container07">
          <h1 className="home-text22">
            <span>Il meglio per</span>
            <br></br>
            <span>la tua vacanza</span>
          </h1>
          <span className="home-text26"></span>
          <Link to="/servizi" className="home-navlink2">
            <Btn
              button="Scopri"
              rootClassName="btn-root-class-name2"
              className="home-component10"
            ></Btn>
          </Link>
        </div>
      </div>
      <div className="home-hero3">
        <div className="home-container08">
          <h1 className="home-text27">
            <span>Da anni il</span>
            <br></br>
            <span>tuo riferimento</span>
            <br></br>
            <span>estivo</span>
            <br></br>
          </h1>
          <span className="home-text34">
            <span className="home-text35">
              Ci impegnamo per coccolarti e farti vivere delle
            </span>
            <br></br>
            <span>giornate di benessere e relax.</span>
            <br></br>
          </span>
        </div>
        <img
          alt="liscai-ruja-illustrazione-mare-shardana"
          src="/fotohero1-min-1200w.png"
          className="home-image3"
        />
      </div>
      <Footer></Footer>
      <a href="#top" className="home-link1">
        <div className="home-container09">
          <svg fill="white" viewBox="0 0 1024 1024" className="home-icon">
            <path d="M798.165 609.835l-256-256c-16.683-16.683-43.691-16.683-60.331 0l-256 256c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
          </svg>
        </div>
      </a>
    </div>
  )
}

export default Home
