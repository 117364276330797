import React from 'react'

import PropTypes from 'prop-types'

import './btn.css'

const Btn = (props) => {
  return (
    <div className={`btn-container ${props.rootClassName} `}>
      <button className="btn-button button">{props.button}</button>
    </div>
  )
}

Btn.defaultProps = {
  button: 'Get Started',
  rootClassName: '',
}

Btn.propTypes = {
  button: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Btn
