import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import CookiePolicy from './views/cookie-policy'
import ChiSiamo from './views/chi-siamo'
import Contatti from './views/contatti'
import Home from './views/home'
import Servizi from './views/servizi'
import LaSpiaggia from './views/la-spiaggia'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={CookiePolicy} exact path="/cookie-policy" />
        <Route component={ChiSiamo} exact path="/chi-siamo" />
        <Route component={Contatti} exact path="/contatti" />
        <Route component={Home} exact path="/" />
        <Route component={Servizi} exact path="/servizi" />
        <Route component={LaSpiaggia} exact path="/la-spiaggia" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
