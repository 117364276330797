import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import FeatureCard4 from '../components/feature-card4'
import Btn from '../components/btn'
import Map from '../components/map'
import Footer from '../components/footer'
import './contatti.css'

const Contatti = (props) => {
  return (
    <div className="contatti-container">
      <Helmet>
        <title>Contatti - Shardana Beach Club Liscia Ruja</title>
        <meta
          name="description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
        <meta
          property="og:title"
          content="Contatti - Shardana Beach Club Liscia Ruja"
        />
        <meta
          property="og:description"
          content="Benvenuti a Liscia Ruja, tra Porto Cervo e Porto Rotondo. Goditi sabbia bianca, mare turchese e il comfort dello stabilimento Shardana per un'esperienza indimenticabile."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name2"></Header>
      <div className="contatti-banner">
        <h1 className="contatti-text">Contatti</h1>
        <span className="contatti-text1">
          <span>
            Il nostro personale è sempre disponibili per qualunque necessità,
          </span>
          <br></br>
          <span>puoi trovarci ai canali qui sotto.</span>
        </span>
      </div>
      <div className="contatti-features">
        <h1 className="contatti-text5">
          <span>Rimani in contatto</span>
          <br></br>
        </h1>
        <div className="contatti-container1">
          <div className="contatti-separator"></div>
        </div>
        <div className="contatti-container2">
          <div className="contatti-container3">
            <a href="mailto:blue.dario@icloud.com?subject=Prenotazione">
              <FeatureCard4
                title="Email"
                imageSrc="/Icons/email-200h.png"
                description="blue.dario@icloud.com"
                rootClassName="rootClassName1"
                className="contatti-component1"
              ></FeatureCard4>
            </a>
            <a href="tel:+393792311700">
              <FeatureCard4
                title="Telefono"
                imageSrc="/Icons/telephone-200h.png"
                description="+39 3792311700"
                rootClassName="rootClassName4"
                className="contatti-component2"
              ></FeatureCard4>
            </a>
            <a
              href="https://www.facebook.com/share/4sBrLCq95hXX6VYp/?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FeatureCard4
                title="Facebook"
                imageSrc="/Icons/facebook-200h.png"
                description=" "
                rootClassName="rootClassName3"
                className="contatti-component3"
              ></FeatureCard4>
            </a>
            <a
              href="https://www.instagram.com/shardanabeachclub.official?igsh=MWxiNGtsZ3lkN3VsZg=="
              target="_blank"
              rel="noreferrer noopener"
            >
              <FeatureCard4
                title="Instagram"
                imageSrc="/Icons/instagram-200h.png"
                description=" "
                rootClassName="rootClassName2"
                className="contatti-component4"
              ></FeatureCard4>
            </a>
          </div>
        </div>
      </div>
      <a
        href="https://widget.spiagge.it/stabilimenti-balneari/prenotazione/it-ss-07026-shardana-beach-club/?ybnl=1"
        target="_blank"
        rel="noreferrer noopener"
        className="contatti-link4"
      >
        <Btn
          button="Prenota ora il tuo ombrellone"
          rootClassName="btn-root-class-name7"
          className="contatti-component5"
        ></Btn>
      </a>
      <div className="contatti-banner1">
        <h1 className="contatti-text8">Come arrivare?</h1>
      </div>
      <Map rootClassName="map-root-class-name1"></Map>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  )
}

export default Contatti
