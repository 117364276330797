import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './map.css'

const Map = (props) => {
  return (
    <div className={`map-container ${props.rootClassName} `}>
      <div className="map-container1">
        <div className="map-container2">
          <Script
            html={`<div style="width: 100%"><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Shardana%20Beach%20Club,%20spiaggia%20Liscia%20Ruja,%2007021%20Arzachena%20SS+(Shardana%20Beach%20Club)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a></iframe></div>`}
            className=""
          ></Script>
        </div>
      </div>
    </div>
  )
}

Map.defaultProps = {
  rootClassName: '',
}

Map.propTypes = {
  rootClassName: PropTypes.string,
}

export default Map
